import Logo from "./../assets/images/logo.png";
import { useEffect, useState } from "react";
import Axios from "axios";
import { requestConfigs } from "../queries/configs";
import { Configs } from "../configs/configs";
import Lollipop from "./../assets/images/lollipop.png";
import Hamper from "./../assets/images/hamper.png";

export const paramsNormalizer = (params) => {
  const parts = [];

  const encode = (val) => {
    return encodeURIComponent(val)
      .replace(/%3A/gi, ":")
      .replace(/%24/g, "$")
      .replace(/%2C/gi, ",")
      .replace(/%20/g, "+")
      .replace(/%5B/gi, "[")
      .replace(/%5D/gi, "]");
  };

  const convertPart = (key, val) => {
    if (val instanceof Date) val = val.toISOString();
    else if (val instanceof Object) val = JSON.stringify(val);

    parts.push(encode(key) + "=" + encode(val));
  };

  Object.entries(params).forEach(([key, val]) => {
    if (val === null || typeof val === "undefined") return;

    if (Array.isArray(val)) val.forEach((v, i) => convertPart(`${key}`, v));
    else convertPart(key, val);
  });

  return parts.join("&");
};

const PRIZE_MAP = {
  lollipop: Lollipop,
  hamper: Hamper,
};

const Home = (props) => {
  const [photos, setPhotos] = useState([]);

  const [configs, setConfigs] = useState({
    photo_wall_display_meta: {
      logo: "",
      tagline: "",
      background_color: "",
    },
  });

  useEffect(() => {
    getData()
    getConfigs()
    setInterval(()=>getData(), 100000);
    setInterval(()=> getConfigs(), 100000);
   
    // SocketConnect();
  }, [props]);

  const SocketConnect = () => {
    const Connection = new WebSocket(
      
        "wss://dd11qfkzg6.execute-api.me-south-1.amazonaws.com/staging?group=photowall"
    );
    Connection.onopen = () => {
      console.log("Socket Connected. ✅");
    };
    Connection.onclose = () => {
      console.log("Socket Disconnected. ❌");
      setTimeout(function () {
        SocketConnect();
      }, 1000);
    };
    Connection.onerror = function (err) {
      console.error(
        "Socket encountered error: ",
        err,
        "Closing socket 🛑"
      );
      setTimeout(function () {
        SocketConnect();
      }, 1000);
    };
    Connection.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log("Updated.");
      debugger;
      if (data.message === "Updated photo-wall") {
        getData();
      }
      if (data.message === "Updated photo-wall-config") {
        getConfigs();
      }
    };
  };

  const getData = () => {
    const baseUrl = Configs.BASE_URL;
    Axios({
      url: `${baseUrl}api/v1/photo_wall`,
      params: {
        status: ["approved_by_moderator", "win"],
        offset: 0,
        limit: 30,
        sort_by: "desc(published_at)",
      },
      paramsSerializer: (params) => paramsNormalizer(params),
    })
      .then((response) => {
        setPhotos(response.data.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getConfigs = async () => {
    try {
      const { data, success } = await requestConfigs();
      if (success) {
        setConfigs({
          photo_wall_display_meta: data.photo_wall_display_meta,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
 

  return (
    <>
      <header
        style={{
          backgroundColor:
            configs.photo_wall_display_meta.background_color || "#d63835",
        }}
      >
        <h2>{configs.photo_wall_display_meta.tagline}</h2>
      </header>
      <main className="container">
        {photos.slice(0, 30).map((photo, index) => {
          {console.log("sdbhfasd",photo.media_type==="MediaTypeEnum.video")}
          return (
            <div className="box" key={index}>
              {photo.media_type === "MediaTypeEnum.video" ? (
                <video
                  poster={Logo}
                  loop
                  muted
                  autoPlay
                  src={photo.s3_file_url}
                />
              ) : (
                <img src={photo.s3_file_url} alt="Chupa Photo" />
              )}
              {photo.prize && (
                <img
                  src={PRIZE_MAP[(photo.prize + "").replace("PrizeEnum.", "")]}
                  alt="Gift Badge"
                  className="badge"
                  title={photo.prize}
                />
              )}
            </div>
          );
        })}
      </main>
      <footer></footer>
    </>
  );
};

export default Home;
