import { query } from "../helpers/query";

export const requestConfigs = () =>
  query({
    uri: 'api/v1/configs',
    method: 'GET'
  });

export const requestConfigsUpdate = payload =>
  query({
    uri: 'api/v1/configs',
    method: 'PATCH',
    data: payload
  });
