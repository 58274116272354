import Axios from 'axios';
import { Configs } from '../configs/configs';



export const query = ({uri,...other}) => new Promise((resolve, reject) => {
	let PAYLOAD = {
		url: Configs.BASE_URL + uri,
		headers: {},
		...other
	};
	// @ts-ignore
	Axios(PAYLOAD).then(response => {
		if(response){
			resolve({
				HTTP_STATUS_CODE: response.status,
				...response.data
			})
		}else
			reject({
				success: false,
				message: 'Unknown Error!!',
				detail: []
			});
	}).catch(error => {
		console.log(error);
		if(error && error.response && error.response.status) {
			console.table({
				uri: uri,
				method: PAYLOAD.method || 'GET',
				status: error.response.status,
				response: error.response.data.detail
			});
			reject(error.response);
		}
		else reject({
			success: false,
			message: 'Unknown Error!',
			detail: []
		});
	})
})