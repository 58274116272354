const IS_DEV = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const IS_PRO = !process.env.NODE_ENV || process.env.NODE_ENV === "production"
// const IS_UAT = !process.env.NODE_ENV || process.env.NODE_ENV === "uat";


const DEV_API = process.env.REACT_APP_DEV_API;
const PRO_API = process.env.REACT_APP_PRO_API;
const UAT_API = process.env.REACT_APP_UAT_API;

const DEV_SOCKET_API = process.env.REACT_APP_DEV_SOCKET_API;
const PRO_SOCKET_API = process.env.REACT_APP_PRO_SOCKET_API;
const UAT_SOCKET_API = process.env.REACT_APP_UAT_SOCKET_API;

// export const Configs = {
//   // BASE_URL: IS_DEV ? DEV_API : PRO_API,
//   BASE_URL: IS_DEV ? DEV_API : IS_PRO ? PRO_API : UAT_API,
//   SOCKET: {
//     url: IS_DEV ? DEV_SOCKET_API : IS_PRO ? PRO_SOCKET_API :UAT_SOCKET_API ,
//     port: 3002
//   },
// };

export const Configs = {
  BASE_URL: IS_DEV ? DEV_API : IS_PRO ? PRO_API : UAT_API,
  SOCKET_URL:  IS_DEV ? DEV_SOCKET_API : IS_PRO ? PRO_SOCKET_API :UAT_SOCKET_API ,
};